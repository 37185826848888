import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
// import { loadFull } from 'tsparticles';
import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from 'split-type';
// import { PopupWidget } from 'react-calendly';
import { animate, stagger} from 'motion';
import Ratio from 'react-bootstrap/Ratio';
import Annoncement from "../../../components/announcement/announcement";
import Embed from "react-embed";
import ArticleTemplate from "../../../templates/articletemplate/articletemplate";
import Audio1 from "../images/audio1.mp3";
import AgentBannerApp from "../images/Prompt-alt.webp";
import AgentBannerImage from "../images/bg-mesh-alt.png";
import AgentBannerVideoWebM from "../images/banner-video.webm";
import AgentBannerVideoMP4 from "../images/banner-video.webm";
import AgentBannerImageAlt from "../images/noise-banner.png";
import AgentBannerImageGrid from "../images/grid.svg";
import AdvisorImageAmazon from "../images/aws-logo.svg";
import AdvisorImageBarclay from "../images/barclay.svg";
import AdvisorImagePlexal from "../images/plexal.svg";
import AdvisorImageCyber from "../images/cyber-runway.svg";
import AdvisorImageIntel from "../images/intel-liftoff.svg";
import AudioBackdrop from "../images/audio-backdrop.svg";
import IntegrationIllstration from "../images/integration.svg";
import IntegrationQuestion from "../images/question.svg";
import AgentImageOne from "../images/agent-1.jpg";
import AgentImageTwo from "../images/agent-2.jpg";
import AgentImageThree from "../images/agent-3.jpg";
import AgentImageFour from "../images/agent-4.jpg";
import Score from "../images/score.svg";
import ScoreCom from "../images/score-com.svg";
import Announcement from "../images/announce-intel-alt.svg";
import AgentStarOne from "./agentStarOne";
import AgentStarTwo from "./agentStarTwo";
import AppShot from "../images/app-user-one.svg";
import AppshotAlt from "../images/app-user-two.svg";
// import FTMCommunity from "../images/flow-community.webp";
// import FTMTrans from "../images/ftm-flow-trans-alt.png";
import "react-tabs/style/react-tabs.css";
import "./index.scss";


// For ad tracking
import Hotjar from '@hotjar/browser';

const siteId = 3788252;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);
// For ad tracking


const LandingAnnouncementContent = () => {
  return (
    <>
      <a
        href="https://www.linkedin.com/posts/saminu-salisu_hackathon-frauddetection-bilic-activity-7115253885136478208-5Wfx/?utm_source=share&utm_medium=member_desktop"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="c-announcement__text">
          <p>
            🎉🎉 <span>Bilic</span> won the intel AI hackathon {" "}
            {/* <span> Intel Corporation Liftoff program </span> */}
            <span className="d-none d-md-inline-block"> </span> {" "}
            🎉🎉{" "}
          </p>
        </div>
        <div className="c-announcement__logo">
          <img
            src={Announcement}
            alt=""
            className="d-none d-md-inline-block"
          />
          <span className="d-none d-md-inline-flex">
            Read More <i className="fas fa-chevron-circle-right"></i>
          </span>
        </div>
      </a>
    </>
  )
};

const BannerVideo = () => {
  return (
    <div className="bilic-article-template__hero__video">
      <video autoPlay playsInline loop muted >
        <source src={AgentBannerVideoWebM} type="video/webm" />
        <source src={AgentBannerVideoMP4} type="video/mp4" />
      </video>
    </div>
  )
}

const SectionAudio = () => {
  return (
    <audio src={Audio1} type="audio/mp3" controls></audio>
  )
}

const AgentTemplateHeroBgImgs = () => (
  <>
    <img
      src={AgentBannerImage}
      alt=""
      className="bilic-article-template__hero__background"
    />
    <img
      src={AgentBannerImageGrid}
      alt=""
      className="bilic-article-template__hero__background--grid"
    />
    <img
      src={AgentBannerImageAlt}
      alt=""
      className="bilic-article-template__hero__background--alt"
    />
    <img
      src={AgentBannerImageAlt}
      alt=""
      className="bilic-article-template__hero__background--alt"
    />
    <img
      src={AgentBannerImageAlt}
      alt=""
      className="bilic-article-template__hero__background--alt"
    />
    <img
      src={AgentBannerImageAlt}
      alt=""
      className="bilic-article-template__hero__background--alt"
    />
    <img
      src={AgentBannerImageAlt}
      alt=""
      className="bilic-article-template__hero__background--alt"
    />
    <img
      src={AgentBannerImageAlt}
      alt=""
      className="bilic-article-template__hero__background--alt"
    />
  </>
);

const ArticleTemplateHero = () => {
  const el = useRef();
  const q = gsap.utils.selector(el);

  useEffect(() => {

    const headingOne = new SplitType('.heading--one', { types: 'words' });
    // const headingTwo = new SplitType('.heading--two', { types: 'lines' });
    const para = new SplitType('#para', { types: 'lines' });
    const heroElements = [...headingOne.words, ...para.lines];

    // el.current = gsap
    //  .timeline()
    //   .from(q('.hidetextref'), {
    //     y: '150%',
    //     ease: 'power4.out',
    //     duration: 0.5,
    //     delay: 0.4,
    //     stagger: 0.6,
    //   })

    animate(heroElements, { y: [28, 0], opacity: [0, 1] }, { duration: 0.5, delay: stagger(0.18) });

    animate('.bilic-global-header', { y: [-28, 0], opacity: [0, 1] }, { duration: 1, delay: stagger(0.5) });

  }, [q]);

  return (
    <>
      <div className="col col-xxl-10">
        <div
          className="bilic-article-template__hero__text d-flex flex-column"
          style={{ position: "relative" }}
        >
          <AgentStarOne />
          <AgentStarTwo />
          <div
            className="w-100 d-flex flex-column align-items-xl-start"
            ref={el}
          >
            <small>Only 8 places left at $50/pm. Original price $299/pm </small>
            <h1 className="heading d-none d-lg-flex">
              <span className="hidetextref heading--one">
                <span>
                  Deliver <span>5star</span>
                  {""}
                  <span>
                    <img
                      src={AppShot}
                      alt=""
                      style={{ marginLeft: "2px", marginRight: "1px" }}
                    />
                    customer
                  </span>{" "}
                  support <br className="d-none d-xl-inline" /> with our AI
                  agents
                  <span>
                    <img src={AppshotAlt} alt="" />{" "}
                  </span>
                </span>
              </span>
            </h1>
            <h1 className="heading d-lg-none">
              <span>
                <span>
                  Deliver 5star customer support {" "} with our AI agents
                </span>{" "}
              </span>
            </h1>
            {/* <h1 className="heading">
          <span className="hidetextref heading--two"><span> team with AI agents</span></span>
        </h1> */}
            <p id="para" className="d-none d-lg-flex">
              Bilic provides a team of AI Agents to handle routine customer
              support. This allows your team to focus on high-value work instead
              of constantly playing catch-up with support tickets.
            </p>
            <p className="d-lg-none">
              Bilic provides a team of AI Agents to handle routine customer
              support. This allows your team to focus on high-value work instead
              of constantly playing catch up with support tickets.
            </p>
            <div className="bilic-article-template__hero__links">
              {/* <Link className="btn bilic-article-template__hero__links__btn" to="/contact">Speak to Sales</Link> */}
              {/* <PopupWidget
            url="https://calendly.com/saminated/60-minutes"
            rootElement={document.getElementById('root')}
            text="Get in touch"
            textColor="#ffffff"
            color="#319795"
          /> */}

              <a
                className="btn bilic-article-template__hero__links__btn"
                to="/contact"
                target="_blank"
                // href="https://staging.bilic.ai/base/audio-video/xr763ho1vjq?id=64ac8ce92b57f4ecfc279e9d"
                href="https://buy.stripe.com/9AQ3d75D71TB2yIeUY"
                rel="noreferrer"
              >
                Sign up
              </a>

              {/* <PopupWidget
                url="https://calendly.com/saminated/60-minutes"
                rootElement={document.getElementById('root')}
                text="Book a free 30min Demo"
                textColor="#50dd29"
                color="#50dd29"
                className="btn main"
              /> */}

              {/* <small>Only 8 places left at this price $50/pm. Original price $299/pm</small> */}

              {/* <a
            className="btn bilic-article-template__hero__links__btn bilic-article-template__hero__links__btn-alt animateanchor"
            target="_blank" href="https://www.veed.io/view/26ea8b7b-d85e-484c-a443-119121709e0f?panel=share" rel="noreferrer">
            Watch a Demo
          </a> */}
              <button
                className="btn bilic-article-template__hero__links__btn bilic-article-template__hero__links__btn-alt animateanchor"
                data-bs-toggle="modal"
                data-bs-target="#agentModal"
              >
                Watch a Demo
              </button>
            </div>
            <small>You can cancel anytime</small>
          </div>
        </div>
      </div>
      {/* <img src={BannerHeadCurve} alt="" /> */}
    </>
  );
};

const ArticleTemplateHeroIllustration = () => {
  useEffect(() => {
    // gsap.registerPlugin(ScrollTrigger);
    // const heroImage = document.querySelector(".bilic-article-template__hero__image");
    // const scrollTarget = document.querySelector("bilic-article-template__content__solutions");


    // animate(".bilic-article-template__hero__image", { transform: 'perspective(1200px) translateX(0px) translateY(-100px) scale(1) rotate(0deg) rotateX(32deg) rotateY(0deg) translateZ(0px)', opacity: [0, 1] }, { duration: 0.8, delay: 0.5 });

    // gsap.to(".bilic-article-template__hero__image", {
    //   transform: 'translateY(0px) rotateX(0deg)',
    //   perspective: 'none',
    //   duration: 2,//
    //   ease: "power2.inOut",
    //   scrollTrigger: {
    //     trigger: '.animateanchor',
    //     start: '00',
    //   },
    // });


    animate(".bilic-article-template__hero__image", { scale: [0, 1], opacity: [0, 1] }, { duration: 0.8, delay: 0.5 });


  }, []);

  return (
    <div className="col">
      <div className="bilic-article-template__hero__image">
        <>
          <BannerVideo />
          <img
            src={AgentBannerApp}
            alt=""
            className=""
          />
        </>
      </div>
    </div>
  )
};


const BVarticle = () => {
  const [showForm, setShowForm] = React.useState(false);
  const handleShowFormState = () => setShowForm(true)//
  // const handleShowFormState = () => setShowForm((current) => !current);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: ''
  });

  const [agentType, setAgentType] = useState('');  // State for the selected agent type

  const handleAgentTypeChange = (e) => {
    setAgentType(e.target.value);
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleButtonClick = async (e) => {
    e.preventDefault();  // Prevents the default form submission behavior

    const { phone, name } = formData;
    const agentNumber = agentType;  // Assuming agentType is a valid agent number

    if (!phone || !agentNumber || !name) {
      alert('Please enter your name, phone number and select an agent');
      return;
    }

    try {
      const url = `https://ddagent-backend-production.up.railway.app/twilio/make-call/${phone}/${name || ''}/${agentNumber}`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Network response was not ok ${response.statusText}`);
      }

      const data = await response.json();
      console.log(data);  // Log the response data for debugging purposes
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
    }
  };


 /* const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);
  */

  // const particlesLoaded = useCallback(async (container) => {
  //   await console.log(container);
  // }, []);

  return (
    <>
      <Annoncement styleModifier="agent">
        <LandingAnnouncementContent />
      </Annoncement>

      <ArticleTemplate
        articletemplatehero={<ArticleTemplateHero></ArticleTemplateHero>}
        articletemplateheroposition="center"
        articletemplateherobgimgs={
          <AgentTemplateHeroBgImgs></AgentTemplateHeroBgImgs>
        }
        articletemplateheroillustration={
          <ArticleTemplateHeroIllustration></ArticleTemplateHeroIllustration>
        }
        templatename="bilic-article-template-agent"
      >
        <section className="bilic-landing__banner__manifest__advisors d-flex flex-column">
          <div className="container">
            <div className="row">
              <div className="col d-flex flex-column align-items-md-center">
                <h4>Bilic Is Backed By The Best</h4>
                <div className="bilic-landing__banner__manifest__advisors__slide">
                  <img src={AdvisorImageAmazon} alt="" />
                  <img src={AdvisorImageBarclay} alt="" />
                  <img src={AdvisorImagePlexal} alt="" />
                  <img src={AdvisorImageCyber} alt="" />
                  <img src={AdvisorImageIntel} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bilic-article-template__content__teaser bilic-article-template__content__teaser-righttext">
          <div className="content-grid content-grid--usecase">
            <div className="content-grid__first"></div>
            <div className="content-grid__second"></div>
            <div className="content-grid__three"></div>
            <div className="content-grid__four"></div>
            <div className="content-grid__five"></div>
          </div>
          <div className="container">
            {/* <img src={BannerCurve} alt="" className="bilic-article-template__content__background-curve bilic-article-template__content__background-curve-one"/> */}
            <div className="row">
              <div className="col-12">
                <div className="bilic-article-template__content__usecase">
                  <h5>
                    <span>
                      Give your <span> customers 24/7 access </span> to reliable
                      support
                    </span>
                  </h5>

                  <p>
                    Tired of basic AI bots that offer poor-quality support?
                    Bilic provides intelligent human-like AI agents tailored to
                    your specific business needs. Our specialised agents act as
                    virtual team members - understanding your products, systems,
                    and customers as intricately as your top human talent.
                  </p>
                </div>
              </div>
              <div className="col-12">
                <div className="agent-list">
                  <h6>
                    See our agents in action -{" "}
                    <span> Click an agent for a complimentary call </span>
                  </h6>
                  <div className="agent-list__body">
                    {[
                      {
                        name: "Business Banking Agent",
                        profile: [
                          <li>
                            Handles account security, transaction disputes, and
                            more
                          </li>,
                          <li>
                            Deep expertise in financial services compliance and
                            regulations
                          </li>,
                          <li>
                            Documents verification, audits, risk analysis,
                            portfolio management
                          </li>,
                        ],
                        image: `${AgentImageOne}`,
                        value: "6530128848a002f428db4724",
                      },
                      {
                        name: "Fintech Fraud Agent",
                        profile: [
                          <li>
                            Specialised knowledge in remittances, global
                            transfers, and more
                          </li>,
                          <li>
                            Helps identify suspicious patterns early, contains
                            threats quickly
                          </li>,
                          <li>
                            Advice for customers on latest fraud schemes and
                            prevention
                          </li>,
                        ],
                        image: `${AgentImageTwo}`,
                        value: "6530183a48a002f428db4734",
                      },
                      {
                        name: "Compliance Expert",
                        profile: [
                          <li>
                            Advises on data privacy, security safeguards,
                            digital governance
                          </li>,
                          <li>
                            Ensures protocols meet newest tech standards and
                            regulations
                          </li>,
                          <li>
                            Helps customers with changing industry rules and
                            audits
                          </li>,
                        ],
                        image: `${AgentImageThree}`,
                        value: "6530195d48a002f428db4737",
                      },
                      {
                        name: "Digital Forensics Agent",
                        profile: [
                          <li>
                            Expertise in cyber threat detection, blockchain
                            investigations
                          </li>,
                          <li>
                            Helps implement advance security by helping identify
                            vulnerabilities
                          </li>,
                          <li>
                            Advises on ransomware and malware attacksAdvises on
                            ransomware and malware attacks
                          </li>,
                        ],
                        image: `${AgentImageFour}`,
                        value: "65301a7a48a002f428db473c",
                      },
                    ].map((agent, index) => (
                      <div className="agent-select" key={index}>
                        <label className="agent-select__radio">
                          <input
                            type="radio"
                            name="agent"
                            value={agent.value}
                            onClick={handleShowFormState}
                            onChange={handleAgentTypeChange}
                            className="form-check-input"
                            id={`agent-${index}`}
                          />
                          <div className="card-body">
                            <div className="agent-select__info">
                              <h5 className="card-title">{agent.name}</h5>
                              <ul className="card-text">{agent.profile}</ul>
                            </div>
                            <div className="card-image">
                              <img
                                src={agent.image}
                                className=""
                                alt={`${agent.name}'s`}
                              />
                            </div>
                          </div>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl">
                {showForm ? (
                  <div className="agent-form">
                    <form>
                      <>
                        {/* <select
                            className="form-select mb-4"  // Bootstrap class for styling
                            value={agentType}
                            onChange={handleAgentTypeChange}
                        >
                            <option value="" disabled>Select an agent you want to hear from</option>
                            <option value="650306d9271396d5ed2a5d49">EMI Agent</option>
                            <option value="support">Support Agent</option>
                            <option value="other">Other</option>
                        </select> */}
                        <div className="form-group">
                          <label htmlFor="name">Name</label>
                          <input
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            value={formData.name}
                            onChange={handleChange}
                            className="form-control mb-3"
                            pattern="^[a-zA-Z\s]{2,30}$" // Regular expression to enforce the format
                            title="Please enter a name containing only letters and spaces, between 2 to 30 characters long" // Tooltip text to explain the format
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Company Email"
                            value={formData.email}
                            onChange={handleChange}
                            className="form-control mb-3"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="phone">Phone Number</label>
                          <input
                            type="tel"
                            name="phone"
                            id="phone"
                            placeholder="Enter the Country code and phone number in format 447, not +447"
                            value={formData.phone}
                            onChange={handleChange}
                            className="form-control mb-3"
                            pattern="^[4][4][7]\d{9}$" // Regular expression to enforce the format
                            title="Please enter a phone number in the format 447415197423" // Tooltip text to explain the format
                          />
                        </div>
                        <button
                          onClick={handleButtonClick}
                          className="btn btn-agent"
                        >
                          Call me in 10 seconds
                        </button>
                      </>

                      {/* <footer className="mt-4 text-white">
                            <p>or call +447488881118</p>
                        </footer> */}
                    </form>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-11">
                <div className="bilic-article-template__content__teaser-wrap">
                  <div className="bilic-article-template__content__teaser__illustration">
                    <img src={AudioBackdrop} alt="" />
                    <SectionAudio />
                    {/* <Tabs
                  defaultActiveKey="profile"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="home" title="Debt Collection Agent">

                    <AudioPlayer src={Audio1} height={60} />
                    <div className="bilic-article-template__content__solutions__list__item">
                      <img src={FTMAudio} alt="" />
                      <h3>An Agent calling a debtor on behalf of a lending company to process payment, provide information on outstanding debt and offer support </h3>
                    </div>
                  </Tab>

                  <Tab eventKey="profile" title="Fraud Support Agent">

                    <AudioPlayer src={Audio1} height={60} />
                    <div className="bilic-article-template__content__solutions__list__item">
                      <img src={FTMAudio} alt="" />
                      <h3>An Agent calling a debtor on behalf of a lending company to process payment, provide information on outstanding debt and offer support </h3>
                    </div>
                  </Tab>

                </Tabs> */}
                  </div>
                  <div className="bilic-article-template__content__teaser__text">
                    <h3>
                      AI Agents Have <span>Unrivaled Natural Language</span>{" "}
                      Capabilities
                    </h3>
                    <p>
                      Debt collection follow-up calls, KYC document verification
                      appointments, new account security check-ins, and general
                      customer support FAQs. Bilic's AI agents conduct natural
                      phone conversations just like your best human
                      representatives.
                    </p>
                    <a
                      href="https://staging.bilic.ai/base/audio-video/xr763ho1vjq?id=64ac8ce92b57f4ecfc279e9d"
                      className="btn"
                    >
                      {" "}
                      Speak To Our AI Agents
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <img src={BannerCurve} alt="" className="bilic-article-template__content__background-curve bilic-article-template__content__background-curve-two"/> */}
          </div>
        </section>

        {/* <section className="bilic-article-template__content__teaser bilic-article-template__content__teaser-lefttext">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-11">
                <div className="bilic-article-template__content__teaser-wrap">
                  <div className="bilic-article-template__content__teaser__text">
                    <h3>
                      Power of <br /> <span>Community</span>
                    </h3>
                    <p style={{ maxWidth: "533px" }}>
                      The platform uses the power of a community of experts like
                      blockchain analysts, blockchain developers, on-chain data
                      analysts, everyday traders and end-users to track,
                      investigate, and solve blockchain-related crimes through
                      the novel intel marketplace. By aggregating data from
                      various 3rd party nodes and off-chain sources the database
                      Adding labels,
                    </p>
                    <a href="https://ftm.bilic.io/marketplace" className="btn">
                      {" "}
                      Demo
                    </a>
                  </div>
                  <div className="bilic-article-template__content__teaser__illustration">
                    <img className="img-fluid" src={FTMCommunity} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="bilic-article-template__content__teaser bilic-article-template__content__teaser-righttext"
          style={{ paddingTop: "13.6rem" }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-11">
                <div
                  className="bilic-article-template__content__teaser-wrap"
                  style={{ gap: "5rem" }}
                >
                  <div className="bilic-article-template__content__teaser__illustration">
                    <img className="img-fluid" src={FTMTrans} alt="" />
                  </div>
                  <div className="bilic-article-template__content__teaser__text">
                    <h3>
                      <span> Transaction history </span> even way back in time
                    </h3>
                    <p>
                      Our FTM dashboard shows the wallet transaction history
                      including funds sent and received, In/Outbound transfers,
                      currencies received, smart contract calls, etc. and
                      highlighted activities involving labeled wallets.
                      Certified investigators or temporary users can trace funds
                      to a KYC’d wallet, link suspected cases, and label
                      wallets.
                    </p>
                    <a href="https://ftm.bilic.io/marketplace" className="btn">
                      {" "}
                      Demo
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="bilic-article-template__bento">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col col-xl-11">
                <div className="bento__grid">
                  <div className="bento__item bento__item-1">
                    <div className="">
                      <h6>24/7 Fraud Support Agent</h6>
                      <p>
                        Armed with the latest scam alerts and security
                        expertise, our intelligent fraud agents vigilantly
                        safeguard your customers against online threats.
                      </p>
                      <ul>
                        <li>
                          Proactive protection against emerging fraud schemes
                        </li>
                        <li>Expert guidance to contain incidents swiftly</li>
                        <li>
                          Ongoing security awareness education for customers
                        </li>
                      </ul>
                      <figure>
                        <img src={Score} alt="" />
                      </figure>
                    </div>
                  </div>
                  <div className="bento__item bento__item-2">
                    <h6>Security Incident Response</h6>
                    <p>
                      Emergencies like data breaches and cyber threats require
                      split-second decisions. Our AI agents act decisively in
                      critical moments based on data insights.
                    </p>
                    <ul>
                      <li>
                        Lightning-fast assessment and action in high-risk
                        incidents
                      </li>
                      <li>Contain threats before they intensify</li>
                      <li>Alert human agents seamlessly</li>
                    </ul>
                    <figure>
                      <img src={IntegrationQuestion} alt="" />
                    </figure>
                  </div>
                  <div className="bento__item bento__item-3">
                    <h6>Compliance Assistant</h6>
                    <p>
                      Our AI agent performs entry-level compliance tasks,
                      ensuring your business and customers stay on the right
                      side of regulations.
                    </p>
                    <ul>
                      <li>Takes care of routine governance tasks</li>
                      <li>Frees your focus for higher value oversight</li>
                      <li>Enables agility to adjust to new policies</li>
                    </ul>
                    <figure>
                      <img src={ScoreCom} alt="" />
                    </figure>
                  </div>
                  <div className="bento__item bento__item-4">
                    <h6>Integrate Bilic in Minutes</h6>
                    <p>
                      Bilic AI agents integrate seamlessly with your existing
                      systems and software through flexible API integration.{" "}
                    </p>
                    <ul>
                      <li>CRM and Marketing Automation</li>
                      <li>Know Your Customer (KYC) legal requirements</li>
                      <li>Calendar and Scheduling integrations</li>
                    </ul>
                    <figure>
                      <img src={IntegrationIllstration} alt="" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="bilic-article-template__content__teaser bilic-article-template__content__teaser-lefttext">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-11">
                <div className="bilic-article-template__content__teaser-wrap">
                  <div className="bilic-article-template__content__teaser__text">
                    <h3>
                      Power of <br /> <span>Community</span>
                    </h3>
                    <p style={{ maxWidth: "533px" }}>
                      The platform uses the power of a community of experts like
                      blockchain analysts, blockchain developers, on-chain data
                      analysts, everyday traders and end-users to track,
                      investigate, and solve blockchain-related crimes through
                      the novel intel marketplace. By aggregating data from
                      various 3rd party nodes and off-chain sources the database
                      Adding labels,
                    </p>
                    <a href="https://ftm.bilic.io/marketplace" className="btn">
                      {" "}
                      Demo
                    </a>
                  </div>
                  <div className="bilic-article-template__content__teaser__illustration">
                    <img className="img-fluid" src={FTMCommunity} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="bilic-article-template__content__teaser bilic-article-template__content__teaser-lefttext">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col">
                <div className="bilic-article-template__content__usecase">
                  <h5>
                    <span>
                      <span>Plug-and-Play </span>
                    </span>
                    AI Customer Support at Your Fingertips{" "}
                  </h5>

                  <p>
                    We handle the heavy lifting so you avoid disruptions. Bilic
                    agents act as plug-and-play enhancements to your current
                    environment using natural language interfaces your teams
                    already know.
                  </p>
                  <p>
                    With customisable contexts, actions, and permissions - our
                    AI agents adapt to your systems and business needs, not the
                    other way around.
                  </p>
                  <p>
                    It’s time to seamlessly augment your customer experience,
                    safeguards, and operations with intelligent automation.
                  </p>
                </div>
              </div>

              <div className="col-12 col-lg-11">
                <div
                  className="bilic-article-template__content__teaser-wrap"
                  style={{ gap: "5rem" }}
                >
                  <div className="bilic-article-template__content__teaser__text">
                    <h3>
                      Watch Bilic's AI Agent Conduct Secure
                      <span> Video Interview</span>
                    </h3>
                    <p>
                      Stop deadline with clunky, impersonal video KYC
                      interviews. Bilic's AI agents make identity verification
                      simple. Customers appreciate the friendly, professional
                      interaction with our AI agents. And your team saves hours
                      of repetitive interview work - with full audit trails for
                      each session.
                    </p>
                    <Link to="/contact" className="btn main">
                      Let’s Discuss Your Needs
                    </Link>
                  </div>
                  <div className="bilic-article-template__content__teaser__illustration">
                    {/* <h6>KYC Video Interview and Onboarding</h6> */}
                    {/* <div style={{ width: 660, height: 'auto' }}> */}
                    <div style={{ width: "100%", height: "auto" }}>
                      <Ratio aspectRatio="16x9">
                        <iframe
                          src="https://www.loom.com/embed/718cf4b77fa0424abefc67b9545e425d?sid=13556bdc-02ec-452d-9610-acec33041a74"
                          title="Vimeo video"
                          allowFullScreen
                        ></iframe>

                        {/* <div style={{ position: "relative", paddingBottom: "47.5%", height: 0 }}>
                          <iframe
                            src="https://www.loom.com/embed/485b9dd190d54473bd4d9b67430f9890?sid=3636798e-8293-4082-9a60-3eb4d3d44dcb"
                            webkitallowfullscreen="true"
                            mozallowfullscreen="true"
                            allowFullScreen={true}
                            style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                          ></iframe>
                        </div> */}
                      </Ratio>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="modal fade bilic-article-template__content__modal"
          id="agentModal"
          aria-labelledby="agentModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="bilic-article-template__content__modal__video">
                  <Embed url="https://vimeo.com/885451959?share=copy" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </ArticleTemplate>
    </>
  );
};

export default BVarticle;