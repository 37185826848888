import React, { useEffect, useRef } from "react";
// import { Link } from "react-router-dom";
import { gsap } from "gsap";
import ArticleTemplate from "../../templates/articletemplate/articletemplate";
import Annoncement from "../../components/announcement/announcement";
// import CheckIcon from "./images/check.svg";
import Announcement from "./images/announce-intel.svg";
import CheckIconOutlined from "./images/check-outline.svg";
import "./pricing.scss";

const LandingAnnouncementContent = () => {
  const el = useRef();
  const tl = useRef();
  useEffect(() => {
    const selectAnimationTarget = gsap.utils.selector(el);
    tl.current = gsap
      .timeline()
      .from(selectAnimationTarget(".c-announcement__text"), {
        opacity: 0,
        y: -20,
        duration: 1,
      })
      .from(selectAnimationTarget(".c-announcement__logo"), {
        opacity: 0,
        y: 20,
        duration: 0.55,
      });
  }, []);

  return (
    <>
      <a
        href="https://www.linkedin.com/posts/saminu-salisu_hackathon-frauddetection-bilic-activity-7115253885136478208-5Wfx/?utm_source=share&utm_medium=member_desktop"
        target="_blank"
        rel="noopener noreferrer"
        ref={el}
      >
        <div className="c-announcement__text">
          <p>
            🎉🎉 <span>Bilic</span> won the intel AI hackathon{" "}
            {/* <span> Intel Corporation Liftoff program </span> */}
            <span className="d-none d-md-inline-block"> </span> 🎉🎉{" "}
          </p>
        </div>
        <div className="c-announcement__logo">
          <img src={Announcement} alt="" className="d-none d-md-inline-block" />
          <span className="d-none d-md-inline-flex">
            Read More <i className="fas fa-chevron-circle-right"></i>
          </span>
        </div>
      </a>
    </>
  );
};

const Pricing = () => (
  <>
    <Annoncement styleModifier="landing">
      <LandingAnnouncementContent />
    </Annoncement>

    <ArticleTemplate
      articletemplateheroposition="center"
      templatename="bilic-article-template-neo bilic-pricing"
    >
      {/* <div className="c-glow">
        <div className="c-glow__wrap">
          <div className="c-glow c-glow--one"></div>
          <div className="c-glow c-glow--two"></div>
        </div>
      </div> */}
      <main className="">
        <section className="bilic-pricing__hero">
          <div className="container">
            <div className="row">
              <div className="col w-100 d-flex flex-column justify-content-center align-items-center">
                <div className="bilic-pricing__hero-copy text-center w-100 d-flex flex-column justify-content-center align-items-center">
                  <h1> Flexible pricing plans with no surprises.</h1>
                  <p></p>
                  <p>
                    Pricing plans designed to accelerate your business. Start
                    today, cancel anytime. Always know what you’ll pay, No
                    hidden fees.
                  </p>
                </div>
                <div className="bilic-pricing__hero-pills">
                  <p>Checks starting from $15</p>
                  <p>Powered by AI & Blockchain</p>
                  <p>FCRA and EEOC compliant</p>
                  <p>Dedicated support team</p>
                  <p>99.9% uptime SLA</p>
                  <p>Secured with Stripe</p>
                </div>
                <div className="bilic-pricing__hero-buttons d-flex gap-3">
                  <a
                    className="btn d-flex gap-3"
                    href="https://verify.bilic.ai/signup/"
                  >
                    Get Started
                    <i className="fa-sharp fa-solid fa-arrow-right"></i>
                  </a>
                  <a
                    className="btn d-flex gap-3"
                    href="https://calendly.com/saurav-3xog/30min"
                  >
                    Request a Demo{" "}
                    <i className="fa-sharp fa-solid fa-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bilic-pricing__content">
          <article className="bilic-pricing__content-price">
            <div className="container">
              <header>
                <div className="row">
                  <div className="col">
                    <header className="w-100 d-flex flex-column justify-content-center align-items-center">
                      <h4>Plans and Pricing Per Applicant</h4>
                    </header>
                  </div>
                </div>
              </header>
              <article>
                <div className="row justify-content-center">
                  <div className="col col-md-9">
                    <article className="bilic-pricing__plans">
                      <div className="bilic-pricing__card">
                        <span className="bilic-pricing__card-standard" />
                        <h2>Basic</h2>
                        {/* <p>Free forever. Really really forever. Trust us.</p> */}
                        <div className="bilic-pricing__card-divider">
                          <div className="monthly-price">
                            <span>
                              <span>
                                <span
                                  style={{
                                    fontSize: "1.6rem",
                                    marginInlineEnd: "0.4rem",
                                    alignSelf: "flex-start",
                                  }}
                                >
                                  $
                                </span>{" "}
                                15
                              </span>
                              <span
                                style={{
                                  fontSize: "1.4rem",
                                  marginInlineStart: "0.8rem",
                                }}
                              >
                                / per applicant
                              </span>
                            </span>
                            Billed per applicant
                          </div>
                          <a
                            href="https://verify.bilic.ai/signup/"
                            className="bilic-pricing__card-btn"
                          >
                            Get Started
                          </a>
                        </div>
                        <ol>
                          <li>
                            <span>
                              <img
                                src={CheckIconOutlined}
                                alt="check outline"
                              />
                            </span>
                            SSN Trace
                          </li>
                          <li>
                            <span>
                              <img
                                src={CheckIconOutlined}
                                alt="check outline"
                              />
                            </span>
                            National Criminal Search
                          </li>
                          <li>
                            <span>
                              <img
                                src={CheckIconOutlined}
                                alt="check outline"
                              />
                            </span>
                            Sex offender Search
                          </li>
                          <li>
                            <span>
                              <img
                                src={CheckIconOutlined}
                                alt="check outline"
                              />
                            </span>
                            Global WatchList Search
                          </li>
                        </ol>
                      </div>
                      <div className="bilic-pricing__card">
                        <span className="bilic-pricing__card-standard">
                          Recommended
                        </span>
                        <h2>Standard</h2>
                        {/* <p>Free forever. Really really forever. Trust us.</p> */}
                        <div className="bilic-pricing__card-divider">
                          <div className="monthly-price">
                            <span>
                              <span>
                                <span
                                  style={{
                                    fontSize: "1.6rem",
                                    marginInlineEnd: "0.4rem",
                                    alignSelf: "flex-start",
                                  }}
                                >
                                  $
                                </span>{" "}
                                45
                              </span>
                              <span
                                style={{
                                  fontSize: "1.4rem",
                                  marginInlineStart: "0.8rem",
                                }}
                              >
                                / per applicant
                              </span>
                            </span>
                            Billed per applicant
                          </div>
                          <a
                            href="https://verify.bilic.ai/signup/"
                            className="bilic-pricing__card-btn bilic-pricing__card-btn--main"
                          >
                            Get Started
                          </a>
                        </div>
                        <ol>
                          <li>
                            <span>
                              <img
                                src={CheckIconOutlined}
                                alt="check outline"
                              />
                            </span>
                            SSN Trace
                          </li>
                          <li>
                            <span>
                              <img
                                src={CheckIconOutlined}
                                alt="check outline"
                              />
                            </span>
                            National Criminal Search
                          </li>
                          <li>
                            <span>
                              <img
                                src={CheckIconOutlined}
                                alt="check outline"
                              />
                            </span>
                            Sex offender Search
                          </li>
                          <li>
                            <span>
                              <img
                                src={CheckIconOutlined}
                                alt="check outline"
                              />
                            </span>
                            Global WatchList Search
                          </li>
                          <li>
                            <span>
                              <img
                                src={CheckIconOutlined}
                                alt="check outline"
                              />
                            </span>
                            All County Search
                          </li>
                        </ol>
                      </div>
                      <div className="bilic-pricing__card">
                        <span className="bilic-pricing__card-standard" />
                        <h2>Professional</h2>
                        {/* <p>Free forever. Really really forever. Trust us.</p> */}
                        <div className="bilic-pricing__card-divider">
                          <div className="monthly-price">
                            <span>
                              <span>
                                <span
                                  style={{
                                    fontSize: "1.6rem",
                                    marginInlineEnd: "0.4rem",
                                    alignSelf: "flex-start",
                                  }}
                                >
                                  $
                                </span>{" "}
                                65
                              </span>
                              <span
                                style={{
                                  fontSize: "1.4rem",
                                  marginInlineStart: "0.8rem",
                                }}
                              >
                                / per applicant
                              </span>
                            </span>
                            Billed per applicant
                          </div>
                          <a
                            href="https://verify.bilic.ai/signup/"
                            className="bilic-pricing__card-btn"
                          >
                            Get Started
                          </a>
                        </div>
                        <ol>
                          <li>
                            <span>
                              <img
                                src={CheckIconOutlined}
                                alt="check outline"
                              />
                            </span>
                            SSN Trace
                          </li>
                          <li>
                            <span>
                              <img
                                src={CheckIconOutlined}
                                alt="check outline"
                              />
                            </span>
                            National Criminal Search
                          </li>
                          <li>
                            <span>
                              <img
                                src={CheckIconOutlined}
                                alt="check outline"
                              />
                            </span>
                            Sex offender Search
                          </li>
                          <li>
                            <span>
                              <img
                                src={CheckIconOutlined}
                                alt="check outline"
                              />
                            </span>
                            Global WatchList Search
                          </li>
                          <li>
                            <span>
                              <img
                                src={CheckIconOutlined}
                                alt="check outline"
                              />
                            </span>
                            All County Search
                          </li>
                          <li>
                            <span>
                              <img
                                src={CheckIconOutlined}
                                alt="check outline"
                              />
                            </span>
                            Employment Verification - 1
                          </li>
                          <li>
                            <span>
                              <img
                                src={CheckIconOutlined}
                                alt="check outline"
                              />
                            </span>
                            Education Verification -1
                          </li>
                          <li>
                            <span>
                              <img
                                src={CheckIconOutlined}
                                alt="check outline"
                              />
                            </span>
                            One County Civil
                          </li>
                        </ol>
                      </div>
                    </article>
                  </div>
                </div>
              </article>
            </div>
          </article>
          <article className="bilic-pricing__features">
            <header>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col col-md-10">
                    <div>
                      <span>Add Ons</span>
                      <h4>Customized Add-ons for your specific needs</h4>
                      <p>
                        Add-ons give you access to more BilicVerify features and
                        are priced as a percentage of your base plan. Only
                        available with all the packages mentioned ablove.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <article className="">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col col-lg-10">
                    <div className="">
                      <div className="row g-4">
                        <div className="col-12 col-md-6">
                          <div className="bilic-pricing__features-card">
                            <h6>Motor Vehicle Records Search</h6>
                            <span>$10 per applicant</span>
                            <p>
                              Verify your candidate’s driving records, license
                              validity, DUIs, and other on road violations if
                              any with great accuracy and reliability
                            </p>
                            <a href="https://verify.bilic.ai/background-checks/driver-and-motor-vehicle/">
                              {" "}
                              Learn More
                            </a>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="bilic-pricing__features-card">
                            <h6>Professional License Check</h6>
                            <span>$10 per applicant</span>
                            <p>
                              Verify trained candidates with a valid license
                              suiting your unique needs. BilicVerify helps you
                              screen your candidate’s professional license along
                              with credentials, validity and date of expiry.
                            </p>
                            <a href="https://verify.bilic.ai/background-checks/driver-and-motor-vehicle/">
                              {" "}
                              Learn More
                            </a>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="bilic-pricing__features-card">
                            <h6>Reference Check</h6>
                            <span>$8 per applicant</span>
                            <p>
                              Verifies previous employers, supervisors, schools,
                              and key employment and educational information.
                              Helps you learn more about a candidate’s
                              background, experiences, and skills.
                            </p>
                            <a href="https://verify.bilic.ai/background-checks/driver-and-motor-vehicle/">
                              {" "}
                              Learn More
                            </a>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="bilic-pricing__features-card">
                            <h6>Employment Check</h6>
                            <span>$15 per applicant</span>
                            <p>
                              Screen your candidate’s history of employment and
                              job title. Each Employment Verification covers a
                              single employer.
                            </p>
                            <a href="https://verify.bilic.ai/background-checks/employment-verification/">
                              {" "}
                              Learn More
                            </a>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="bilic-pricing__features-card">
                            <h6>Civil Search per County</h6>
                            <span>$10 per applicant</span>
                            <p>
                              Screen candidates at County and Federal Civil
                              court level. Get additional information about your
                              candidate for faster, reliable and accurate
                              hiring.
                            </p>
                            <a href="https://verify.bilic.ai/background-checks/civil-court-checks/">
                              {" "}
                              Learn More
                            </a>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="bilic-pricing__features-card">
                            <h6>Education Check</h6>
                            <span>$12 per applicant</span>
                            <p>
                              Screen your candidate’s date of attendance,
                              university, degree obtained and their respective
                              GPA’s effortlessly. Each Education Verification
                              covers a single school or university.
                            </p>
                            <a href="https://verify.bilic.ai/background-checks/education-verification/">
                              {" "}
                              Learn More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </article>
        </section>

        <section className="bilic-cta">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col col-lg-10">
                <div className="bilic-cta-wrap">
                  <div className="bilic-cta__copy">
                    <div>
                      <h4>Not sure which plan to choose?</h4>
                      <p>
                        Affordable screening solutions for businesses of
                        different sizes. Run instant and reliable checks with
                        the most flexible package which suits your specific
                        needs.
                      </p>
                    </div>
                    <a
                      href="https://calendly.com/saurav-3xog/30min?month=2025-04"
                      className="c-cta__link"
                      alt="Contact us"
                    >
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bilic-faq">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10">
                <div>
                  <header className="bilic-faq__header">
                    <h4>Frequently asked questions, answered.</h4>
                    <p>Some of most frequently asked questions.</p>
                  </header>
                  <div className="bilic-faq__widget">
                    <details>
                      <summary>Is my data safe?</summary>{" "}
                      <p>
                        BilicVerify is powered by the Blockchain and its state
                        of the art technology keeps your data safe & secure.
                        User privacy is our utmost priority and we’re committed
                        to provide you with world class security.
                      </p>
                    </details>
                    <details>
                      <summary>
                        What are the checks that come under the special “Add-on”
                        ?
                      </summary>
                      <p>
                        BilicVerify offers special Add-on checks which makes it
                        easy for users to choose their preferred packages. We
                        have Add-on options for MVR checks, Employee & Education
                        verification, Professional License Verification, ID
                        Verification and Civil Search per County.
                      </p>
                    </details>
                    <details>
                      <summary>
                        Are there any set-up fees for running checks?
                      </summary>
                      <p>
                        BilicVerify charges no setup and contract fees. Pricing
                        is made simple, transparent,and completely hassle free.
                      </p>
                    </details>
                    <details>
                      <summary>Can I avail discounts?</summary>
                      <p>
                        BilicVerify offers discounts for Enterprise companies
                        who are interested in running high volume checks for
                        their employees. Write to us at bgv-usa@bilic.ai and
                        we’ll provide you with the most flexible pricing
                        package.
                      </p>
                    </details>
                    <details>
                      <summary>
                        How reliable are the results obtained from BilicVerify?
                      </summary>{" "}
                      <p>
                        Background verifications executed using BilicVerify are
                        highly accurate and transparent. BilicVerify goes back 7
                        to 20 years to gather records to verify the accuracy of
                        the information collected.
                      </p>
                    </details>
                    <details>
                      <summary>
                        Do you have a monthly or annual subscription?
                      </summary>
                      <p>
                        {" "}
                        No, we don’t have a subscription model. At BilicVerify
                        you’ll always know what you pay for and pricing is made
                        completely transparent and super simple.
                      </p>
                    </details>
                    <details>
                      <summary>Is there a dedicated support team ?</summary>
                      <p>
                        At BilicVerify, we provide live support 24x7 to answer
                        your questions and doubts. Our dedicated support team is
                        highly capable of making you comfortable by answering
                        all your doubts with high quality and less response
                        time.
                      </p>
                    </details>
                    {/* <details>
                      <summary>
                        At what rate was the dollar charged to naira?
                      </summary>
                      <p>
                        bilic is not responsible for the rates, the exchange
                        rate is determined by the market rate
                      </p>
                    </details> */}
                    <details>
                      <summary>What are Add-on checks?</summary>
                      <p>
                        Add-ons checks are special checks which are not a part
                        of the premium packages offered. You can avail Add-ons
                        checks without choosing a plan which makes it highly
                        flexible and user friendly.
                      </p>
                    </details>
                    <details>
                      <summary>Is BilicVerify FCRA, EEOC compliant?</summary>
                      <p>
                        As a Consumer Reporting Agency (CRA), BilicVerify is
                        completely in compliance with all state and federal
                        reporting requirements like the Fair Credit Reporting
                        Act (FCRA) and abiding laws such as Equal Employment
                        Opportunity Commission (EEOC)
                      </p>
                    </details>
                    <details>
                      <summary>
                        Is adverse action notice sent to the candidate if
                        there’s any irregularities found?
                      </summary>
                      <p>
                        BilicVerify provides both Pre/Post Adverse Action Notice
                        to candidates and gives them a chance to view the
                        reports and make their statement based on the
                        irregularities found.
                      </p>
                    </details>
                    {/* <details>
                      <summary>What you can't use your card for</summary>
                      <ul>
                        <li>Money Transfers</li>
                        <li>Travel Related Arrangement Services</li>
                        <li>Outbound Telemarketing Merchants</li>
                        <li>Non-Financial Institutions/Cryptocurrency</li>
                        <li>Security Brokers/Dealers</li>
                        <li>Dating and Escort Services</li>
                        <li>Massage Parlors</li>
                        <li>Government-owned Lotteries</li>
                        <li>
                          Government-licensed Online Casinos (Online Gambling)
                        </li>
                        <li>Government-licensed Horse/Dog Racing</li>
                        <li>Betting and Online Gambling</li>
                      </ul>
                    </details> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </ArticleTemplate>
  </>
);

export default Pricing;
