import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  useLocation,
  withRouter,
} from 'react-router-dom';
import Landing from './pages/landing/landing';
import Team from './pages/team/team';
import Terms from './pages/legal/terms/terms';
import FundsArticle from './pages/articles/fundsarticle/fundsarticle';
import FTMArticle from './pages/products/FTM';
import IMP from './pages/products/IMP';
import PR from "./pages/products/PR";
import IDWrticle from './pages/products/IDW';
import BVarticle from './pages/products/BV';
import ContactArticle from './pages/contact';
import NEOArticle from './pages/products/NEO';
import DEMOArticle from './pages/products/DEMO';
import AgentArticle from './pages/products/Agent/index'
import TrainingPage from "./pages/products/Training";
import AgentCall from './pages/products/AC/index'
import Pricing from "./pages/pricing/pricing";

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}

const ScrollToTop = withRouter(_ScrollToTop);

const Routes = () => (
  <Router>
    <ScrollToTop>
      <Route path="/landing" component={Landing} exact />
      <Route path="/" component={NEOArticle} exact />
      <Route path="/team" component={Team} />
      <Route path="/terms" component={Terms} />
      <Route path="/pricing" component={Pricing} />
      <Route path="/articles/funds" component={FundsArticle} />
      <Route path="/product/follow-the-money" component={FTMArticle} />
      <Route path="/product/intelligence-marketplace" component={IMP} />
      <Route path="/product/pattern-recognition" component={PR} />
      <Route path="/contact" component={ContactArticle} />
      <Route
        path="/product/intelligence-data-warehouse"
        component={IDWrticle}
      />
      <Route path="/product/bilic-verify" component={BVarticle} />

      <Route path="/product/neo" component={NEOArticle} />
      <Route path="/public/demo" component={DEMOArticle} />
      <Route path="/agents" component={AgentArticle} />
      <Route path="/training" component={TrainingPage} />
      <Route path="/public/ac" component={AgentCall} />
    </ScrollToTop>
  </Router>
);

export default Routes;