import { Link } from "react-router-dom";
import {MegaMenu} from "./megaMenu"

export const LandingHeaderLinks = () => (
    <ul className="nav bilic-global-header__navigation__nav">
        {/* <li className="bilic-global-header__navigation__link">
            <Link to="/">Home</Link>
        </li> */}

        <MegaMenu></MegaMenu>
        <li className="bilic-global-header__navigation__link">
            <a
                target="_blank"
                href="https://medium.com/bilic-io" rel="noreferrer">Research
            </a>
        </li>
             <li className="bilic-global-header__navigation__link">
      <a  href="https://osf.io/dbf4n/"
            target="_blank"
            rel="noreferrer">
        Thesis
      </a>
    </li>
        <li className="bilic-global-header__navigation__link">
            <Link to="/contact">Contact</Link>
        </li>

    </ul>
);

export const LandingHeaderButton = () => (
    <div className="bilic-global-header__navigation__btn">
        {/* <a
            className="btn main"
            type="button"
            href="https://osf.io/dbf4n/"
            target="_blank"
            rel="noreferrer"
        >
            <span>Thesis</span>
        </a> */}
        <a
            className="btn main link-cta"
            type="button"
            // href="https://docs.google.com/presentation/d/1wagLYCsTeyV49IHFbumvwSEWa6zbANaolnSV_EJ7G9k/present?slide=id.g11966f4e18d_0_11"
            // href="https://www.docdroid.net/cZS9S6f/bilic-brochure-pitch-updated-pdf"
           target="_blank"
        //    href="https://pitch.com/public/fca572ab-be5a-4f46-bd7e-f1998c99a888/84d1d2ec-674b-4f76-a71b-e1bb077ea984"
           href="https://app.pitch.com/app/presentation/a4ffcb7f-17af-4aa6-bb81-48f193e70451/b8976d8b-ef4a-431e-a2f5-15f6a297ccd1"
            rel="noreferrer"
        >
            <span>Overview Deck</span>
        </a>
    </div>
);



export const ArticleHeaderLinks = () => (
  <ul className="nav bilic-global-header__navigation__nav justify-content-lg-start">
    <MegaMenu></MegaMenu>
    {/* <li className="bilic-global-header__navigation__link">
            <Link to="/">Neo</Link>
        </li> */}
    <li className="bilic-global-header__navigation__link">
      <Link to="/agents">AI Agent</Link>
    </li>
    <li className="bilic-global-header__navigation__link">
      <a href="https://medium.com/bilic-io" rel="noreferrer">
        Research
      </a>
    </li>
    <li className="bilic-global-header__navigation__link">
      <Link to="/pricing">Pricing</Link>
    </li>
  </ul>
);


export const ArticleHeaderMobileLinks = () => (
  <ul>
    <li>
      <ul className="nav flex-column">
        {/* <h5>By Industry</h5> */}
        <li>
          <Link to="/">Neo</Link>
        </li>
        <li>
          <Link to="/agents">AI Agent</Link>
        </li>
        <li>
          <Link to="/pricing">Pricing</Link>
        </li>
        <li>
          <a href="https://medium.com/bilic-io" rel="noreferrer">
            Research
          </a>
        </li>
      </ul>
    </li>
  </ul>
);


export const TeamsHeaderLinks = () => (
  <ul className="nav bilic-global-header__navigation__nav justify-content-lg-start">
    <MegaMenu></MegaMenu>
    <li className="bilic-global-header__navigation__link">
      <Link to="/product/neo">Neo</Link>
    </li>
    <li className="bilic-global-header__navigation__link">
      <Link to="/agents">AI Agent</Link>
    </li>
    <li className="bilic-global-header__navigation__link">
      <Link to="/pricing">Pricing</Link>
    </li>
    <li className="bilic-global-header__navigation__link">
      <a target="_blank" href="https://medium.com/bilic-io" rel="noreferrer">
        Research
      </a>
    </li>
  </ul>
);

export const TeamsHeaderButton = () => (
    <div className="bilic-global-header__navigation__btn">
        <a
            className="btn main"
            type="button"
            target="_blank"
            href="https://www.docdroid.net/1StA7em/pitch-public-pdf" rel="noreferrer"
        >
            <span>Overview Deck</span>
        </a>
    </div>
);